.container{
    min-height: 100vh;
}

.ui.card .meta, .ui.cards > .card .meta {
    color: rgba(0, 0, 0, .7) !important;
}

.ui.feed > .event > .content .summary {
    margin: -.5rem 0 0 !important;
}

.ui.feed > .event > .content .date {
    margin: 0 !important;
}